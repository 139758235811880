<template>
  <div class="grid formgrid p-fluid">
    <OverlayPanel ref="detail">
        <BasicDatatable :rows="details" :headers="headers" 
        :headerVisible="false" 
        :footerVisible="false"
        :rowsFilter="false" :rowaction="true">
        <template #custom-row-button="{ slotScope }">
            <Button :loading="slotScope.loading" label="TERMINAR" @click="finishItem(slotScope)"></Button>
        </template>
    </BasicDatatable>
    </OverlayPanel>
    <PaymentSidebar 
    :paymentTypes="paymentTypes" 
    :ticket="selectedTicket"
    v-model="paymentVisible"
    @hide="paymentVisible = false" 
    @pay="addPayment" 
    @edit="editPayment"
    @delete="deletePayment" 
    @save="savePayment" />
    <Loader v-model="loading" />
      <div class="col-12">
        <Card>
            <template #title>
                Pedidos
            </template>
            <template #content>
                <div class="grid formgrid p-fluid">
                    <div class="col-12 field">
                        <table style="width: 100%; border-spacing: 0 6px; font-size: large; text-align: left;">
                        <tr>
                            <th>Orden</th>
                            <th>Inicio</th>
                            <th>Tiempo</th>
                            <th>Tipo</th>
                            <th>Estatus</th>
                            <th>Total</th>
                            <th style="width: 25%"></th>
                        </tr>
                        <tr v-for="ticket in tickets" :key="ticket.id">
                            <td>
                                <i @click="openDetail($event, ticket)" class="pi pi-eye" style="cursor: pointer;"></i>  
                                #{{ticket.id_number}}
                            </td>
                            <td>{{moment(ticket.date).format("h:mm a")}}</td>
                            <td>{{ticket.minutos}} minutos</td>
                            <td>MESA</td>
                            <td>
                                <ProgressBar :class="ticket.progressClass" :value="ticket.progressValue">
                                    {{ticket.progressText}}
                                </ProgressBar>
                            </td>
                            <td>{{formatCurrency(ticket.total)}}</td>
                            <td>
                                <div class="grid p-fluid">
                                    <div v-if="!ticket.is_paid" class="col-12" style="margin-bottom:.2rem; padding-top: .2rem;">
                                        <Button :loading="ticket.loading" label="Pagar" icon="pi pi-money-bill" @click="openPayment(ticket)"/> 
                                    </div>
                                    <div class="col-6">
                                        <Button :loading="ticket.loading" label="Imprimir" icon="pi pi-print" />
                                    </div>
                                    <div class="col-6">
                                        <Button :loading="ticket.loading" label="Terminar" icon="pi pi-send" @click="finish(ticket)"/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </table>
                    </div>
                </div>
            </template>
        </Card>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import BasicDatatable from '../../components/general/BasicDatatable.vue'
import Loader from '../../components/general/Loader.vue'
import FormMixin from '../../mixins/form.js'
import { ticket } from '../../models/ticket'
import formatCurrency from '../../utilities/formatCurrency'
import { fillObject, HeaderGrid } from '../../utilities/General'
import { basicData } from "../../data/basicData";
import PaymentSidebar from '../../components/PaymentSidebar.vue'
import { printer_payload } from '../../models/printer_payload'

export default {
    components: {Loader, BasicDatatable, PaymentSidebar},
    mixins: [FormMixin],
    data() {
        return  {
            paymentTypes: [],
            paymentVisible: false,
            loading: false,
            selectedTicket: new ticket(),
            tickets: [],
            details: [],
            headers: [
                new HeaderGrid('Cantidad', 'quantity'),
                new HeaderGrid('Nombre','name'),
                new HeaderGrid('Nota','note')
            ],
        }
    },
    methods: {
        moment: moment,
        formatCurrency: formatCurrency,
        async finishItem(item) {
            try {
                item.loading = true;
                let updatedItem =  await new ticket(this.session).prepareFinishItem(item.id);
                item = fillObject(item, updatedItem);
                this.showSuccess("Producto terminado con exito");
            } catch (error) {
                this.showError(error);
            } finally {
                item.loading = false;
            }
        },
        deletePayment(payment) { 
            let id_number = payment.id_number;
            this.selectedTicket.payments = this.selectedTicket.payments.filter(x => x.id_number != payment.id_number);
            this.selectedTicket.payments.forEach(x => {
                if (x.id_number > id_number) 
                x.id_number = x.id_number - 1;
            });
        }, 
        async savePayment(response) {
            try {
                this.selectedTicket.is_invoiceable = false;
                if (response != null) 
                    this.selectedTicket.is_invoiceable = response.event == "invoice";
                
                this.paymentVisible = false;
                this.loading = true;
                let newTicket = await this.selectedTicket.pay();
                this.selectedTicket = fillObject(this.selectedTicket, newTicket);

                let index = this.tickets.findIndex(x => x.id == this.selectedTicket.id);
                if (index >= 0)
                {
                this.tickets[index].is_paid = true;
                }
                //Imprimimos el ticket
                let printer_data = new printer_payload();
                printer_data.name = this.selectedTicket.branch_name;
                printer_data.taxInfo = this.selectedTicket.branch_tax_info;
                printer_data.address = this.selectedTicket.branch_address;
                printer_data.email = this.selectedTicket.branch_email;
                printer_data.date = this.selectedTicket.date;
                printer_data.id = this.selectedTicket.id_number.toString();
                printer_data.footer = "Gracias por su compra!";
                printer_data.phoneNumber = this.selectedTicket.branch_phone_number;
                printer_data.subtotal = this.selectedTicket.subtotal;
                printer_data.total = this.selectedTicket.total;
                printer_data.taxes = this.selectedTicket.iva;
                if (this.selectedTicket.id_customer) {
                let customer = this.customers.find(x => x.id == this.selectedTicket.id_customer);
                printer_data.customerInfo.address = customer.address;
                printer_data.customerInfo.email = customer.email;
                printer_data.customerInfo.name = customer.name;
                printer_data.customerInfo.phoneNumber = customer.phone;
                printer_data.customerInfo.taxInfo = customer.rfc;
                }
                this.selectedTicket.items.forEach(item => {
                printer_data.products.push({
                    quantity: item.quantity, 
                    description: item.name, 
                    sku: item.key_name, 
                    price: item.unit_price, 
                    total: item.total
                });
                });
                this.selectedTicket.payments.forEach(payment => {
                printer_data.change = printer_data.change + payment.amount;
                printer_data.payments.push({
                    name: payment.name,
                    amount: payment.amount
                });
                });
                printer_data.change = printer_data.change - this.selectedTicket.total;
                printer_data.openCashDrawer = true;
                this.printer_data = printer_data;
                this.printer_type = "payment";
                this.printerDialogVisible = true;
                this.selectedTicket.is_paid = true;
                if (this.selectedTicket.is_paid && this.selectedTicket.prepare_status == 3) {
                    this.tickets = this.tickets.filter(x => x.id == this.selectedTicket.id);
                }
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        addPayment(payment) {
            payment.id_number = this.selectedTicket.payments.length + 1;
            this.selectedTicket.payments.push(payment);
        },
        editPayment(payment) {
            let index = this.selectedTicket.payments.findIndex(x => x.id_number == payment.id_number);
            this.selectedTicket.payments[index] = payment;
        },
        openDetail(event,ticket) {
            this.details = ticket.items;
            this.$refs.detail.toggle(event);
        },
        openPayment(myTicket)
        {
            this.selectedTicket = fillObject(this.selectedTicket, myTicket);
            this.selectedTicket.id_company = this.session.id_company;
            this.selectedTicket.id_branch = this.session.id_branch;
            this.selectedTicket.user = this.session.user;
            this.selectedTicket.token = this.session.token;
            this.paymentVisible = true;
        },
        async finish(myTicket) {
            myTicket.loading = true;
            try {
                let newTicket = await new ticket(this.session).prepareFinish(myTicket.id);
                myTicket = fillObject(myTicket, newTicket);
                this.tickets.forEach(ticket => {
                    ticket.minutos = moment().diff(moment(ticket.date), 'minutes');
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });


            } catch (error) {
                this.showError(error);
            } finally {
                myTicket.loading = false;
            }
        }
    },
    async mounted() {
        this.loading = true;
        try {
            this.ticket = new ticket(this.session);
            this.paymentTypes = await new basicData(this.session).getPaymentTypes();
            this.tickets = await new ticket(this.session).pending();
            this.tickets.forEach(ticket => {
                    ticket.minutos = moment().diff(moment(ticket.date), 'minutes');
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });
            
            let vueData = this;
            this.lifeInterval = setInterval(function () {
                //MODIFICAMOS EL ARRAY
                vueData.tickets.forEach(ticket => {
                    ticket.minutos = moment().diff(moment(ticket.date), 'minutes');
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });
                
            }, 30000);

            this.lifeInterval2 = setInterval(async function () {
                vueData.tickets = await new ticket(vueData.session).pending();
                vueData.tickets.forEach(ticket => {
                    ticket.minutos = moment().diff(moment(ticket.date), 'minutes');
                    ticket.progressClass = 
                    ticket.prepare_status == 3 ? 'progress-early'
                    : ticket.minutos < 10 ? 'progress-early'
                    : ticket.minutos < 20 ? 'progress-ok'
                    : ticket.minutos < 30 ? 'progress-need'
                    : 'progress-out';
                    ticket.progressText = ticket.prepare_status == 3 ? 'Preparado'
                    : ticket.minutos < 10 ? 'Nuevo'
                    : ticket.minutos < 20 ? 'En tiempo'
                    : ticket.minutos < 30 ? 'Necesario'
                    : 'Retrasado';
                    ticket.progressValue = 
                    ticket.prepare_status == 3 ? 100
                    : ticket.minutos < 10 ? 25
                    : ticket.minutos < 20 ? 50
                    : ticket.minutos < 30 ? 75
                    : 100;
                });
            }, 100000);
        } catch (error) {
            this.showError(error);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style>

.p-listbox-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.p-listbox-list-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

/* Handle */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
.p-listbox-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
.container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.progress-early .p-progressbar-value {
    background-color: #33a151;
}
.progress-ok .p-progressbar-value {
    background-color: yellow;
}
.progress-need .p-progressbar-value {
    background-color: orange;
}
.progress-out .p-progressbar-value {
    background-color: red;
}
</style>